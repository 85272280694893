<template>
    <div>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!tableOptions.loading">
                    <tr>
                        <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="tableOptions.loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(item, idx) in tableOptions.tableData" :key="idx" v-show="!tableOptions.loading">

                        <td>
                            <span>{{ item.id }}</span>
                        </td>
                        <td>
                            {{ item.user }}
                        </td>
                        <td>
                            {{ item.date }}
                        </td>
                        <td style="height: 90px;" class="d-flex justify-center">
                            <app-table-delete-modal
                                v-if="enableDelete"
                                :tableRecord="item"
                                :serverRoute="serverRoute"
                                @record-deleted="reFetchData"
                            ></app-table-delete-modal>
                            <v-icon color="green" style="cursor: pointer" class="px-2" @click="backupDownload(item)">
                                mdi-arrow-down-bold-circle-outline
                            </v-icon>
                        </td>
                    </tr>
                </v-fade-transition>
            </v-simple-table>
            <div class=" d-flex justify-end mt-5" v-if="!tableOptions.loading">
                <app-pagination :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"  :perPage="getPerPage()"
                    @PaginationValue="fetchNewPaginated($event)"></app-pagination>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import spinner from "./spinner.vue";
import pagination from "./pagination.vue";
import axios from "axios";
import { saveAs } from 'file-saver';
import tableDeleteModal from "./tableDeleteModal.vue";



export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        appTableDeleteModal: tableDeleteModal,

    },
    data() {
        return {

            deleteDialog: false,
        };
    },

    props: {
        serverRoute:{
            type: String,
            default: "backup",
        },
        enableDelete: {
            type: Boolean,
            default: true,
        },
        flaggedItems: {
            type: Array,
            default() {
                return [];
            },
        },
        displaySettings: {
            type: Boolean,
            default: true,
        },
        tableOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    // computed: {
    //   ...mapGetters({
    //     tableOptions: "tablesStore/getTableOptions",
    //   }),
    // },
    methods: {
        getPerPage() {
      if (this.tableOptions.tableMeta.perPage) {
        return this.tableOptions.tableMeta.perPage;
      } else {
        return 10;
      }
    },
        async exportToExcel(queryParams) {
            try {
                const excel = await axios.get(`backup/${queryParams}`, {
                    
                    responseType: 'blob'
                });
                saveAs(excel.data)

                this.$Notifications(this.$t("Backup was downloaded successfully"),{ rtl: true, timeout: 1000 },"success");
            } catch (err) {
                console.log('err', err);
            } finally {
            }
        },
        getImgUrl(item) {
            let domain = "https://lbbis-back.incentive-dev.com/"
            return domain + item
        },
        backupDownload(item) {
            try {
                // let url = this.getImgUrl(item.url);
                // window.open(url);
                // download(JSON.stringify(url), "apidata.json", "text/plain");
                // this.downloadFile(item.id);
                this.exportToExcel(item.id);
                
                // this.$Notifications(this.$t("Backup was downloaded successfully"),{ rtl: true, timeout: 1000 },"success");
            } catch (error) {
                console.log(error);
            }
        },
        async downloadFile(id) {
            try {
                const response = await axios.get(`backup/${id}`); 
                const data = response.data;

                let link = document.createElement('a'); 
                link.href = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)); 
                link.download = 'data.json'; 
                link.click();
                this.$Notifications(this.$t("Backup was downloaded successfully"),{ rtl: true, timeout: 1000 },"success");
            } catch (error) { console.error('Error downloading the file:', error); }
        },
        async backupDelete(item) {
            try {

                const res = await axios.delete(`backup/${item.id}`);
                this.$Notifications(this.$t("Backup was deleted successfully"), { timeout: 1000, rtl: true } );
                this.reFetchData();
            } catch (error) {
                console.log(error);
            }
        },
        castItems(item) {
            let copyItem = { ...item };
            this.flaggedItems.forEach((el) => {
                delete copyItem[el];
            });
            return copyItem;
        },
        accessData(row, index) {
            return this.fetchAttributes(
                row,
                this.tableOptions.accessTableData[index]
            );
        },
        fetchAttributes(row, nestedKey) {
            let value = row;
            for (let key in nestedKey) {
                if (value[nestedKey[key]]) {
                    value = value[nestedKey[key]];
                } else {
                    return;
                }
            }
            return value.toLocaleString();
        },
        fetchNewPaginated(paginationValue) {
            this.$emit("re-fetch-paginated-data", paginationValue);
        },
        reFetchData() {
            this.$emit("re-fetch-data");
        },
    },
};
</script>
  
<style ></style>